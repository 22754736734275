<template>
  <div>
    <vs-table stripe :data="evalution_details">
      <template slot="header">
        <div style="display: flex;">
          <vs-button color="primary" icon="arrow_back" @click="$router.push({ name: 'StudentConnect' })" size="small">
          </vs-button> &nbsp;
          <h3 style="margin-bottom: 10px;">
            {{ capitalize(evaluation_type) }} [{{ evaluation_count }}]
          </h3>
        </div>
      </template>
      <template slot="thead">
        <vs-th> Can Id </vs-th>
        <vs-th> Candidate Name </vs-th>
        <vs-th> Spoc Name </vs-th>
        <vs-th> Level </vs-th>
        <vs-th> Email </vs-th>
        <vs-th> Course </vs-th>
        <vs-th> Enrollment Date </vs-th>
        <vs-th> Action </vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].identity">
            {{ data[indextr].identity }}
          </vs-td>
          <vs-td :data="data[indextr].person_name">
            {{ data[indextr].person_name }}
          </vs-td>
          <vs-td :data="data[indextr].spoc_name">
            {{ data[indextr].spoc_name }}
          </vs-td>
          <vs-td :data="data[indextr].level">
            {{ data[indextr].level }}
          </vs-td>
          <vs-td :data="data[indextr].email">
            {{ data[indextr].email }}
          </vs-td>
          <vs-td :data="data[indextr].courses">
            {{ data[indextr].courses }}
          </vs-td>
          <vs-td :data="data[indextr].enrollment_date">{{
              data[indextr].enrollment_date
          }}</vs-td>
          <vs-td :data="data[indextr].identity">
            <div @click="openPopupEmit(data[indextr])">
              <vs-icon icon="info_outline" size="small" color="dark"></vs-icon>
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination style="padding-top: 1%;" :total="total_pages" v-model="page_no"></vs-pagination>
  </div>
</template>

<script>
// import axios from "axios";
// import constants from "../../../constants.json";
import ApiServices from "../../ApiServices";
import moment from "moment";
export default {
  mounted() {
    this.evaluation_type = this.$route.params.evaluation_detail.evaluation_type;
    this.evaluation_count = this.$route.params.evaluation_detail.evaluation_count;
    console.log("this.evaluation_header", this.$route.params.evaluation_detail.evaluation_header, this.evaluation_type)
    this.getEvaluationDoneDetails(this.page_no);
  },
  watch: {
    page_no(value) {
      this.getEvaluationDoneDetails(value);
    },
  },
  data: () => ({
    evaluation_header: "",
    evalution_details: [],
    evaluation_count: '',
    evaluation_type: "",
    page_no: 1,
    total_pages: 0,
  }),
  methods: {
    capitalize(val) {
      const str = val.replace(/_/g, " ");
      const arr = str.split(" ");
      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);

      }
      const str2 = arr.join(" ");
      return str2

    },
    getEvaluationDoneDetails(page) {
      this.$vs.loading();
      // let url = `${constants.MILES_DASHBOARDS}evaluationDetails?evaluation_type=${this.evaluation_type}&page=${page}`;
      // axios
      //   .get(url, {
      //     headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
      //   })
      ApiServices.evaluationDetails(this.evaluation_type, page)
        .then((response) => {
          this.evalution_details = [];
          var date = null;
          for (let i = 0; i < response.data.data.length; i++) {
            if (response.data.data[i].enrollment_date !== undefined) {
              date = moment
                .unix(response.data.data[i].enrollment_date)
                .format("DD-MMM-YYYY");
            } else {
              date = 0;
            }
            var obj = {
              identity: response.data.data[i].identity,
              person_name: response.data.data[i].person_name,
              level: response.data.data[i].level,
              courses: response.data.data[i].courses,
              email: response.data.data[i].email,
              enrollment_date: date,
              spoc_name: response.data.data[i].spoc_name
            };
            this.evalution_details.push(obj);
          }
          this.total_pages = response.data.last_page;
          this.$vs.loading.close();
        })
        .catch((error) => {
          console.log(error);
          this.$vs.loading.close();
        });
    },
    openPopupEmit(payload) {
      console.log("payload", payload);
      this.openBigPopup(payload.mwb_id);
    },
  },
};
</script>